<template>
  <b-card>
    <form-component @onSubmit="onSubmit" :sale-representative="saleRepresentative" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/sale-representatives/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';
import { show, update } from '@/http/sale-representatives';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      saleRepresentative: {
        id: '',
        county_id: '',
        about_us_id: false,
        name: '',
        surname: '',
        phone: '',
        email: '',
      },
    };
  },

  async mounted() {
    await this.fetchSaleRepresentative();
  },

  methods: {
    async fetchSaleRepresentative() {
      try {
        const { data } = await show(this.$route.params.id);

        this.saleRepresentative = data.data;
        this.$set(this.saleRepresentative, 'county_id', this.saleRepresentative.county);
      } catch (error) {
        this.showErrorNotification(
          'Problem z pobraniem danych',
          'Nie udało się pobrać danych przedstawiciela handlowego.',
        );
      }
    },
    async onSubmit(form) {
      try {
        await update(this.saleRepresentative.id, form);

        this.showSuccessNotification('Dane zapisane', 'Przedstawiciel handlowy został zaktualizowany.');
        this.$router.push({ name: 'sale-representatives-index' });
      } catch (error) {
        const message =
          error.response.data.message ??
          'Wystąpił błąd podczas dodawania przedstawiciela handlowego. Skontaktuj się ze swoim developerem.';

        this.showErrorNotification('Problem z zapisaniem danych', message);
      }
    },
  },
};
</script>
